export const gallery = [
  { url: "/static/media/20161113_185054.jpg", text: "", id: 1 },
  { url: "/static/media/20161113_185100.jpg", text: "", id: 2 },
  { url: "/static/media/20161114_194153.jpg", text: "", id: 3 },
  { url: "/static/media/20161209_214638.jpg", text: "", id: 4 },
  { url: "/static/media/20170422_165150.jpg", text: "", id: 5 },
  { url: "/static/media/20170427_183450.jpg", text: "", id: 6 },
  { url: "/static/media/20170427_183528.jpg", text: "", id: 7 },
  { url: "/static/media/20170512_160629.jpg", text: "", id: 8 },
  { url: "/static/media/20170513_105158.jpg", text: "", id: 9 },
  { url: "/static/media/20170513_105221.jpg", text: "", id: 10 },
  { url: "/static/media/20170513_105321.jpg", text: "", id: 11 },
  { url: "/static/media/20170714_125228.jpg", text: "", id: 12 },
  { url: "/static/media/20170714_125235.jpg", text: "", id: 13 },
  { url: "/static/media/20170714_125239.jpg", text: "", id: 14 },
  { url: "/static/media/20170714_125249.jpg", text: "", id: 15 },
  { url: "/static/media/20190515_155341.jpg", text: "", id: 16 },
  { url: "/static/media/20190515_155343.jpg", text: "", id: 17 },
  { url: "/static/media/20190515_155345.jpg", text: "", id: 18 },
  { url: "/static/media/20190515_155352.jpg", text: "", id: 19 },
  { url: "/static/media/20190515_155428.jpg", text: "", id: 20 },
  { url: "/static/media/20190515_155431.jpg", text: "", id: 21 },
  { url: "/static/media/20190515_155443.jpg", text: "", id: 22 },
  { url: "/static/media/20190515_155450.jpg", text: "", id: 23 },
  { url: "/static/media/20190805_155201.jpg", text: "", id: 24 },
  { url: "/static/media/20190805_155211.jpg", text: "", id: 25 },
  { url: "/static/media/20190805_155230.jpg", text: "", id: 26 },
  { url: "/static/media/20190805_155244.jpg", text: "", id: 27 },
  { url: "/static/media/20190805_155249.jpg", text: "", id: 28 },
  { url: "/static/media/20190805_155252.jpg", text: "", id: 29 },
  { url: "/static/media/20190805_155254.jpg", text: "", id: 30 },
  { url: "/static/media/20190805_155351.jpg", text: "", id: 31 },
  { url: "/static/media/20190805_155355.jpg", text: "", id: 32 },
  { url: "/static/media/20190814_200246.jpg", text: "", id: 33 },
  { url: "/static/media/20190814_200257.jpg", text: "", id: 34 },
  { url: "/static/media/20190814_200320.jpg", text: "", id: 35 },
  { url: "/static/media/20190820_125356.jpg", text: "", id: 36 },
  { url: "/static/media/20190820_125359.jpg", text: "", id: 37 },
  { url: "/static/media/20190820_125401.jpg", text: "", id: 38 },
  { url: "/static/media/20190820_125435.jpg", text: "", id: 39 },
  { url: "/static/media/20190905_173748.jpg", text: "", id: 40 },
  { url: "/static/media/20190905_173813.jpg", text: "", id: 41 },
  { url: "/static/media/20190905_173845.jpg", text: "", id: 42 },
  { url: "/static/media/20190905_173903.jpg", text: "", id: 43 },
  { url: "/static/media/20191010_225250.jpg", text: "", id: 44 },
  { url: "/static/media/20191010_225305.jpg", text: "", id: 45 },
  { url: "/static/media/20191019_180808.jpg", text: "", id: 46 },
  { url: "/static/media/20191107_130155.jpg", text: "", id: 47 },
  { url: "/static/media/20191107_130202.jpg", text: "", id: 48 },
  { url: "/static/media/20191122_161136.jpg", text: "", id: 49 },
  { url: "/static/media/20191128_170001.jpg", text: "", id: 50 },
  { url: "/static/media/20191205_021659.jpg", text: "", id: 51 },
  { url: "/static/media/20191205_202605.jpg", text: "", id: 52 },
  { url: "/static/media/20191205_202621.jpg", text: "", id: 53 },
  { url: "/static/media/20191211_151556.jpg", text: "", id: 54 },
  { url: "/static/media/20191211_151611.jpg", text: "", id: 55 },
  { url: "/static/media/20191223_164753.jpg", text: "", id: 56 },
  { url: "/static/media/20191223_164811.jpg", text: "", id: 57 },
  { url: "/static/media/20200116_204632.jpg", text: "", id: 58 },
  { url: "/static/media/20200121_214703.jpg", text: "", id: 59 },
  { url: "/static/media/20200222_144732.jpg", text: "", id: 560 },
];
