import React from "react";
import "./Gallery.css";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { Element } from "react-scroll";

import { gallery } from "../../Utils/GALLERY.js";

export default function Gallery() {
  console.log(gallery);
  const cards = gallery.map(({ url, text: TEXT, id }) => {
    const text = `<span>${id}</span>`;

    return (
      <LightgalleryItem group={`item`} subHtml={text} src={url}>
        <div class="gallery__item">
          <img className="gallery__item-img" src={url} alt="" />
        </div>
      </LightgalleryItem>
    );
  });

  return (
    <Element name="realizations">
      <div className="menubgc"></div>
      <div className="gallery">
        <div data-aos="fade-right" className="gallery__text">
          <h2>NASZE REALIZACJE</h2>
          <p className="gallery__text-paragraph">
            Szeroki zakres wiedzy oraz wieloletnie doświadczenie w branży
            zajmującej się montażem instalacji sanitarnych, gwarantujemy
            najwyższą jakość wykonywanych usług dla klienta. Niezależnie od
            rodzaju zlecenia mogą Państwo liczyć na pełen profesjonalizm,
            dyspozycyjność i pełne ukierunkowanie na potrzeby klienta.
          </p>
        </div>
        <div className="gallery__grid">
          <LightgalleryProvider>{cards}</LightgalleryProvider>
        </div>
      </div>
    </Element>
  );
}
