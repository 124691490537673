import React, { Component } from "react";
import "./OfferDetails.css";
import { Element } from "react-scroll";

const offer = [
  {
    id: 1,
    url:
      "https://cdn.pkt.pl/f-16790-instalacja-wodno-kanalizacyjna-projekt-materialy-i-montaz.jpg",
    mainText:
      "Montaż instalacji wodno- kanalizacyjnych wewnętrznych i zewnętrznych",
    text: "",
  },
  {
    id: 2,
    url:
      "http://sanrad.pl/wp-content/uploads/2016/03/instalacje--987x1024.jpg",
    mainText: "Montaż instalacji grzewczych wewnętrznych i zewnętrznych",
    text: "",
  },
  {
    id: 3,
    url: "https://poradnikprojektanta.pl/wp-content/gallery/instalacja-gazowa/Instalacja_gazowa_10.jpg",
    mainText: "Montaż instalacji gazowych wewnętrznych i zewnętrznych",
    text: "",
  },
  {
    id: 4,
    url:
      "https://static.kb.pl/media/uploads/media_image/original/wpis/2150/instalacja-gazowa.jpg",
    mainText: "Montaż kotłów gazowych",
    text: "",
  },
  {
    id: 5,
    url: "https://cdn.galleries.smcloud.net/t/galleries/gf-5tqF-jryX-XR6a_gruntowa-pompa-ciepla-664x442-nocrop.jpg",
    mainText: "Montaż pomp ciepła",
    text: "",
  },
  {
    id: 6,
    url:
      "https://totalcms.wpcdn.pl/articles/df3df8648639e4be0b904947d7521c35.jpeg",
    mainText: "Montaż układów solarnych",
    text: "",
  },
  {
    id: 7,
    url:
      "https://www.euro.com.pl/fckeditor/image/kontent-poradnik/34814-1.jpg",
    mainText: "Montaż zmiękczaczy wody użytkowej",
    text: "",
  },
  {
    id: 8,
    url:
      "https://static.kb.pl/media/uploads/media_image/auto/entry-content/422/desktop/wiercenie-w-betonie.jpg",
    mainText: "Cięcie i wiercenie w betonie",
    text: "",
  },
];

export default function OfferDetails() {
  return (
    <>
      <Element name="aplication">
        <div className="menubgc"></div>
        <div
          data-aos="fade-up"
          className="offer__text"
          style={{ margin: "5vh auto 10vh", padding: "0 5vw" }}
        >
          <h2 className="offer__text-main">NASZA OFERTA</h2>
          <p className="offer__text-paragraph">
            Szeroki zakres wiedzy oraz wieloletnie doświadczenie w branży
            instalacji sanitarnych pozwala nam zaoferować najwyższą jakość
            wykonywanych usług. Niezależnie od rodzaju zlecenia mogą Państwo
            liczyć na pełen profesjonalizm, dyspozycyjność i pełne
            ukierunkowanie na Państwa potrzeby.
          </p>
        </div>
        {offer.map(({ id, url, mainText }) => (
          <section className="offer-details" data-aos="fade-up">
            <div
              className={`offer-details__text ${
                id % 2 !== 0 ? "left" : "right"
              }`}
            >
              <h2 className="offer-details__main-text">{mainText}</h2>
              {/* <p className="offer__text-paragraph">
                Szeroki zakres wiedzy oraz wieloletnie doświadczenie w branży
                instalacji sanitarnych pozwala nam zaoferować najwyższą jakość
                wykonywanych usług. Niezależnie od rodzaju zlecenia mogą Państwo
                liczyć na pełen profesjonalizm, dyspozycyjność i pełne
                ukierunkowanie na Państwa potrzeby.
              </p> */}
            </div>
            <div
              style={{ backgroundImage: `url(${url})` }}
              className={`offer-details__img ${
                id % 2 === 0 ? "left" : "right"
              }`}
            />
          </section>
        ))}
        <div style={{ height: "10vh" }} />
      </Element>
    </>
  );
}
